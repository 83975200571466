/**
 * React dependencies
 */
import { useSelector, useDispatch } from 'react-redux';

/**
 * Local dependencies
 */
import { snackbarActions } from 'store/slices/snackbar';

/**
 * External dependencies
 */
import MUISnackBar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

/**
 * A wrapper component for the Material UI Snackbar.
 * It uses the values stored in the snackbar store slice.
 */
const Snackbar = () => {
  const {
    isOpen,
    message,
    severity
  } = useSelector(state => state.snackbar);
  const dispatch = useDispatch();
  
  /**
   * Close Snackbar
   * 
   * Provides a button for the user to dismiss the snackbar on error.
   */
  const closeSnackbar = () => {
    dispatch(snackbarActions.hideSnackBar());
  }

  // Dismiss button
  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={closeSnackbar}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <MUISnackBar
      autoHideDuration={5000}
      open={isOpen}
      onClose={closeSnackbar}
      action={action}
    >
      <Alert onClose={closeSnackbar} severity={severity}>
        { message }
      </Alert>
    </MUISnackBar>
  );
};

export default Snackbar;
/**
 * Local dependencies
 */
import { COLOR_GOLD_DARK, COLOR_SILVER } from 'constants/colors';

const config = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 100,
  dragFriction: 0.12,
  duration: 1000,
  stagger: 3,
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: [COLOR_GOLD_DARK, COLOR_SILVER]
};

export default config;
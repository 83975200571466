/**
 * React dependencies
 */
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

/**
 * External dependencies
 */
import _get from 'lodash.get';

/**
 * Local dependencies 
 */
import { ROUTE_DATA_CAPTURE } from 'constants/routes'; 
import Button from 'components/Button';
import { getRandomJoke } from 'utils/axios';
import { snackbarActions } from 'store/slices/snackbar';
import { SEVERITY_ERROR } from 'constants/snackbar';

/**
 * This is the page that the user lands on after scanning their QR code.
 * It shows a loading animation while validating the venue passed in the URL 
 * against the database and then loads the cracker or an error message
 * depending on the output of the validation.
 * 
 * @param {Object} props - React object properties 
 * @returns 
 */
const Joke = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [joke, setJoke] = useState();
  const dispatch = useDispatch();

  /**
   * Use Effect to grab a joke from the server.
   */
  useEffect( () => {
    getRandomJoke()
      .then( result => {
        const { data } = result.data;
        if( data ) {
          setJoke({
            question: data.joke_question,
            answer: data.joke_answer
          });
        }
      })
      .catch( e => {
        dispatch(snackbarActions.setSeverity({
          severity: SEVERITY_ERROR
        }));
        dispatch(snackbarActions.setMessage({
          message: 'Server connection error.'
        }));
        dispatch(snackbarActions.showSnackBar(true));
      });
      return () => {
        setJoke(null);
      };
  }, [dispatch]);

  /**
   * Continue Handler
   * Navigates the user to the data capture form.
   */
  const continueHandler = () => {
    navigate(`${ROUTE_DATA_CAPTURE}${search}`);
  }

  return (
    <section className='Joke'>
      <h1>Let's start with a little festive joke...</h1>
      <div className="Joke__content">
        { _get(joke, 'question') && <p>Q: { joke.question }</p> }
        { _get(joke, 'answer') && <p className="Joke__answer">A: { joke.answer }</p>}
      </div>
      <Button
        variant='contained'
        className='mx-auto'
        onClick={continueHandler}
      >
        Continue
      </Button>
    </section>
  );
};

export default Joke;
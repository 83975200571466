/**
 * Local dependencies
 */
import { SEVERITY_INFO } from 'constants/snackbar';

const initialState = {
  isOpen: false,
  message: "",
  severity: SEVERITY_INFO,
};

export default initialState;

/**
 * React dependencies
 */
 import { useSelector } from 'react-redux';

/**
 * This is the final view of the app where the
 * user sees whether they won or lost.
 * 
 * @param {Object} props - React object properties 
 */
const Result = () => {
  const { prize, userWon } = useSelector(state => state.api);
  return (
    <section className="Result">
      { userWon ? (
        <div className="Result__win">
          <p className="mb-3 h3"><strong>Santa's come early!</strong></p>
          <img src='/images/winner.png' alt="Winner badge" width="285" height="72"/>
          <h1 className="mt-3 mb-1">You've won a cracking Christmas prize!</h1>
          <img className="mb-2 Result__img" src='/images/separator.png' width="151" height="22" alt="Separator"/>
          <p>Head to the bar with your email voucher to claim.</p>
        </div>
      ) : (
        <div className="Result__loss">
          <h1 className="mb-3 h3">Christmas is here,<br />but sadly this time a prize isn't 😔</h1>
          <img className="mb-2  Result__img" src='/images/out-of-luck.png' alt="Out of luck badge" width="285" height="72"/>
          <img className="my-2" src='/images/separator.png' alt="Separator" width="151" height="22"/>
          <p>Enjoy the festivities. </p>
        </div>
      )}
    </section>
  );
};

export default Result;
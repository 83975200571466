/**
 * Redux dependencies
 */
import { configureStore } from '@reduxjs/toolkit';

/**
 * Local dependencies
 */
import apiSlice from 'store/slices/api';
import cookieSlice from 'store/slices/cookies';
import snackbarSlice from 'store/slices/snackbar';

const store = configureStore({
  reducer: {
    api: apiSlice.reducer,
    cookies: cookieSlice.reducer,
    snackbar: snackbarSlice.reducer,
  },
});

export default store;

/**
 * React dependencies
 */
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';

/**
 * External dependencies
 */
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

/**
 * Local dependencies 
 */
import Cracker from 'components/Cracker';

/**
 * This is the page that the user lands on after scanning their QR code.
 * It shows a loading animation while validating the venue passed in the URL 
 * against the database and then loads the cracker or an error message
 * depending on the output of the validation.
 * 
 * @param {Object} props - React object properties 
 * @returns 
 */
const Home = ({ loadingVenue }) => {
  const { venue } = useSelector(state => state.api);

  return (
    <section className="Home">
      { loadingVenue && (
        <div className="Home__loading">
          <h1>Loading your venue...</h1>
          <Stack spacing={1}>
            <Skeleton variant="text" />
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={118} />
          </Stack>
          </div>
      ) }
      { !loadingVenue && !!venue && (
        <div className="Home__cracker">
          <Cracker />
        </div>
      ) }
      { !loadingVenue && !venue && (
        <div className="Home__error">
          <h1>We couldn't find your venue.</h1>
          <p>Please scan the QR code and try again.</p>
        </div>
      ) }
    </section>
  );
};

export const HomeProps = {
  loadingVenue: PropTypes.bool,
};

export const HomeDefaults = {
  loadingVenue: false,
};

Home.propTypes = HomeProps;
Home.defaultProps = HomeDefaults;

export default Home;
/**
 * React dependencies
 */
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Confetti from 'react-dom-confetti';

/**
 * External dependencies
 */
import _random from 'lodash.random';

/**
 * Local dependencies
 */
import { ROUTE_JOKE } from 'constants/routes';
import { stretchCracker, fadeOut, fadeIn, pullCrackerApart } from 'utils/animations';
import confettiConfig from 'constants/confetti';

/**
 * A Christmas cracker interactive element
 * that links to the data capture form.
 * 
 * @param {Object} props - React element properties
 */
const Cracker = () => {
  const topCrackerHalfRef = useRef(null);
  const bottomCrackerHalfRef = useRef(null);
  const instructionsRef = useRef(null);
  const merryXmasRef = useRef(null);
  const crackerHelpRef = useRef(null);
  const [showCrackerHelp, setShowCrackerHelp] = useState(true);
  const [showInstructions, setShowInstructions] = useState(true);
  const [showMerryXmas, setShowMerryXmas] = useState(true);
  const [showConfetti, showShowConfetti] = useState(false);
  const [noOfclicks, setNoOfClicks] = useState(5);
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    // Pick a random number between 1 and 5 to set the number
    // of clicks that the user needs before cracking the cracker 
    setNoOfClicks(_random(1, 5));
  }, []);

  /**
   * Remove and fade out element
   * 
   * Fades out an element and removes it from the DOM
   * 
   * @param {Object} ref - element to remove 
   * @param {Function} setShowElement - function that sets the visibility of the element
   */
  const removeAndFadeOutElement = (ref, setShowElement) => {
    fadeOut(ref.current, setShowElement);
  }

  /**
   * Instructions click handler
   * 
   * Hides the instructions and shows the helper element.
   */
  const instructionsOnClick = () => {
    removeAndFadeOutElement(instructionsRef, setShowInstructions);
    fadeIn(crackerHelpRef.current);
  }

  /**
   * Navigate to joke
   * 
   * Redirects the user to the Joke page
   */
  const navigateToJoke = () => {
    navigate(`${ROUTE_JOKE}${search}`);
  }

  /**
   * Click handler for the cracker trigger.
   * It triggers the cracker animation & links
   * to the data capture form
   */
  const pullCracker = () => {
    setNoOfClicks(noOfclicks - 1);

    // If the cracker is cracked
    if (!noOfclicks) {
      // Show confetti
      showShowConfetti(true);

      // Hide Merry Christmas & helper elements
      removeAndFadeOutElement(merryXmasRef, setShowMerryXmas);
      removeAndFadeOutElement(crackerHelpRef, setShowCrackerHelp);

      // Pull cracker halves apart
      pullCrackerApart(topCrackerHalfRef.current, 'top', navigateToJoke);
      pullCrackerApart(bottomCrackerHalfRef.current, 'bottom');
    } else {
      // Stretch each cracker half
      stretchCracker(topCrackerHalfRef.current);
      stretchCracker(bottomCrackerHalfRef.current)
    }
  };

  return (
    <div className='Cracker'>
      <div className='Cracker__confetti-wrapper'>
        <div className='Cracker__confetti'>
          <Confetti
            active={showConfetti}
            config={confettiConfig}
          />
        </div>
      </div>
      <button className='Cracker__ui' onClick={pullCracker}>
        {showCrackerHelp && <span className='Cracker__help' ref={crackerHelpRef}>Tap the cracker to pull</span>}
        <span className='Cracker__half Cracker__half--top' ref={topCrackerHalfRef} />
        {showMerryXmas && <span className='Cracker__merryxmas' ref={merryXmasRef} />}
        <span className='Cracker__half Cracker__half--bottom' ref={bottomCrackerHalfRef} />
      </button>
      { showInstructions && (
        <div
          className='Cracker__instructions'
          onClick={instructionsOnClick}
          ref={instructionsRef}
        >
          <h1 className="h2">Be a Winner</h1>
          <p>Tap to see if you've won a naughty-but-nice prize.</p>
        </div>
      )}
    </div>
  );
};

export default Cracker;
/**
 * External dependencies
 */
import axios from "axios";
import _forOwn from 'lodash.forown';

const instance = axios.create({
  baseURL: process.env.REACT_APP_LEVY_ECRCKER_API_URL,
  timeout: 30000
});

/**
 * Get Random Joke
 * 
 * Returns a random joke from the back-end API.
 * 
 * @returns {Promise}
 */
export const getRandomJoke = () => {
  return new Promise( ( resolve, reject ) => {
    instance.get( '/jokes/random' )
        .then( response => {
            resolve( response );
        } )
        .catch( e => {
            reject( e );
        });
  } );
};

/**
 * Submit Form Data
 * 
 * Submits the user filled out form fields to the back-end API.
 * 
 * @param {Object} data Form data to send to the back-end API. 
 * @returns {Promise}
 */
export const submitFormData = ( data => {
  const formData = new FormData();
  _forOwn( data, ( value, key ) => {
    formData.append( key, value );
  } );

  return new Promise( ( resolve, reject ) => {
    instance.post( '/entries/submit', formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    } )
        .then( response => {
          resolve( response );
        })
        .catch( e => {
          reject( e );
        });
  } );
});

/**
 * Check Venue
 * 
 * Checks the slug passed is for a valid venue by contacting the back-end API.
 * 
 * @param {String} slug The slug passed as a venue query string. 
 * @returns {Promise}
 */
export const check_venue = ( slug => {
  return new Promise( ( resolve, reject ) => {
    instance.get( `/venues/${ slug }` )
      .then( result => {
        resolve( result );
      })
      .catch( e => {
        reject( e );
      });
  } );
});
/**
 * React dependencies
 */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/**
 * External dependencies
 */
import Cookies from 'js-cookie';
import _isBoolean from 'lodash.isboolean';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import Slide from '@mui/material/Slide';

/**
 * Local dependencies
 */
import { cookieActions } from 'store/slices/cookies/';
import { COOKIE_ACCEPTANCE } from 'constants/cookies';
import Button from 'components/Button';

/**
 * Cookie Disclaimer
 * 
 * Cookie disclaimer notification to display across the site. Controls 
 * cookie placement on acceptance and works with the centralised store to
 * control display.
 */
const CookieDisclaimer = () => {
  const dispatch = useDispatch();
  const { notificationDisplay, notificationDeclined } = useSelector(state => state.cookies);

  /**
   * Use Effect to read cookie value and show notification if not set on mount.
   */
  useEffect(() => {
    const displayNotification = Cookies.get(COOKIE_ACCEPTANCE);

    if (displayNotification) {
      dispatch(cookieActions.setNotificationDisplay(false));
      dispatch(cookieActions.setEnableAnalyticsCookies(true));
    } else if (!_isBoolean(notificationDeclined)) {
      dispatch(cookieActions.setNotificationDisplay(true));
    }
  }, [dispatch, notificationDeclined]);

  /**
   * Handle Accept
   * 
   * Triggers the closure of the cookie modal and places a
   * cookie on the user's machine showing that they have accepted
   * the use of cookies. Cookie expires in 365 days. Message will
   * be shown again after cookie has expired.
   */
  const handleAccept = () => {
    Cookies.set(COOKIE_ACCEPTANCE, true, { expires: 365 });
    dispatch(cookieActions.setNotificationDisplay(false));
    dispatch(cookieActions.setEnableAnalyticsCookies(true));
  }

  /**
   * Handle Decline
   * 
   * Triggers when the user declines to have analytics tracking
   * cookies & non-essential cookies placed on their system. Whether
   * or not to display the notification message is set in the store
   * rather than stored in a cookie value.
   */
  const handleDecline = () => {
    dispatch(cookieActions.setNotificationDisplay(false));
    dispatch(cookieActions.setNotificationDeclined(true));
  }

  return (
    <Slide direction="down" in={notificationDisplay} mountOnEnter unmountOnExit timeout={500}>
      <div className="CookieDisclaimer">
        <div className="container">
          <div className="CookieDisclaimer__content">
            <h5 className="CookieDisclaimer__title">Let us know you agree to cookies.</h5>
            <div className="CookieDisclaimer__copy-container">
              <p className="CookieDisclaimer__copy">
                We use <a href="https://www.compass-group.co.uk/about/cookie-policy/" className="CookieDisclaimer__text-link">cookies</a> to give you the best online experience. Please let us know if you agree to all of these cookies.
              </p>
            </div>
            <div className="CookieDisclaimer__buttons">
              <Button
                variant="contained"
                className="CookieDisclaimer__accept mb-2"
                endIcon={<CheckIcon />}
                onClick={handleAccept}
              >
                Accept
              </Button>
              <Button
                variant="contained"
                className="CookieDisclaimer__decline"
                endIcon={<DoNotDisturbIcon />}
                onClick={handleDecline}
              >
                No, Thanks
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
}

export default CookieDisclaimer;

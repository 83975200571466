/**
 * React dependencies
 */
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

/**
 * Local dependencies
 */
import './assets/scss/index.scss';
import store from './store/';
import Layout from './components/Layout';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Layout />
      </Router>
    </Provider>
  );
}

export default App;

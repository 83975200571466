/**
 * React dependencies
 */
import { useEffect, useState } from 'react';
import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga';

/**
 * External dependencies
 */
import _get from 'lodash.get';

/**
 * Local dependencies
 */
import {
  ROUTE_DATA_CAPTURE,
  ROUTE_RESULT,
  ROUTE_ANY,
  ROUTE_JOKE,
  QUERY_VENUE,
} from 'constants/routes';
import { SEVERITY_ERROR } from 'constants/snackbar';
import { apiActions } from 'store/slices/api';
import { snackbarActions } from 'store/slices/snackbar';
import Home from 'pages/Home';
import DataCapture from 'pages/DataCapture';
import Joke from 'pages/Joke';
import Result from 'pages/Result';
import CookieDisclaimer from 'components/CookieDisclaimer';
import Snackbar from 'components/Snackbar';
import useQuery from 'hooks/useQuery';
import { check_venue } from 'utils/axios';

const Layout = () => {
  const [loadingVenue, setLoadingVenue] = useState(false);
  const [showCrackedCracker, setShowCrackedCracker] = useState(false);
  const location = useLocation();
  const query = useQuery();
  const queryVenue = query.get(QUERY_VENUE);
  const dispatch = useDispatch();
  const { venue } = useSelector(state => state.api);
  const { enableAnalyticsCookies } = useSelector( state => state.cookies );

  useEffect(() => {
    if ([ROUTE_DATA_CAPTURE, ROUTE_JOKE, ROUTE_RESULT].includes(location.pathname)) {
      setShowCrackedCracker(true);
    } else {
      setShowCrackedCracker(false);
    }
  }, [location]);

  // Fetches the venue information from the api
  // if there's a venue passed as a query string in the URL
  useEffect(() => {
    setLoadingVenue(true);
    if (queryVenue) {
      check_venue( queryVenue )
        .then( result => {
          const id   = _get( result, 'data.data[0].venue_id', undefined );
          const slug = _get( result, 'data.data[0].slug', undefined );
          const venue_name = _get( result, 'data.data[0].name', undefined );
          const venue_type = _get( result, 'data.data[0].venue_type', undefined );
          const privacy_policy = _get( result, 'data.data[0].privacy_policy', undefined );


          dispatch( apiActions.setVenue({
            venue: {
              id, 
              slug,
              venue_name,
              venue_type,
              privacy_policy
            }
          }));
          setLoadingVenue(false);
        })
        .catch( e => {
          setLoadingVenue(false);
          dispatch(snackbarActions.setSeverity({
            severity: SEVERITY_ERROR
          }));
          dispatch(snackbarActions.setMessage({
            message: 'Server connection error.'
          }));
          dispatch(snackbarActions.showSnackBar(true));
        })
    } else {
      // error handling
      setLoadingVenue(false);
    }
  }, [queryVenue, dispatch]);

  //Google Analytics:
  useEffect(() => {
    if (enableAnalyticsCookies) {
      const trackingId = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_LEVY_ECRACKER_GA_UI : 'UA-211860213-1';
      ReactGA.initialize(trackingId);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [enableAnalyticsCookies]);

  return (
    <div className='Layout'>
      <div className='Layout__radial' />
      <div className='Layout__snowflakes'>
        <div className='container' />
      </div>
      <div className={['Layout__frame', showCrackedCracker ? 'Layout__frame--cracker' : ''].join(' ').trim()}>
        { showCrackedCracker && <span className='Cracker__half Cracker__half--top' /> }
        <main className='Layout__main container'>
          <Routes>
            { venue && (
              <>
                <Route path={ROUTE_JOKE} element={<Joke />} />
                <Route path={ROUTE_DATA_CAPTURE} element={<DataCapture />} />
                <Route path={ROUTE_RESULT} element={<Result />} />
              </>
            )}
            <Route path={ROUTE_ANY} element={<Home loadingVenue={loadingVenue}/>}/>
          </Routes>
        </main>
        { showCrackedCracker && <span className='Cracker__half Cracker__half--bottom' /> }
      </div>
      <CookieDisclaimer />
      <Snackbar />
    </div>
  );
};

export default Layout;
/**
 * URL paths
 */
export const ROUTE_ANY = '*';
export const ROUTE_HOME = '/';
export const ROUTE_JOKE = '/festive-joke';
export const ROUTE_DATA_CAPTURE = '/enter-the-competition';
export const ROUTE_RESULT = '/competition-result';

/**
 * URL query strings
 */
export const QUERY_VENUE = "venue";
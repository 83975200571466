/**
 * React dependencies
 */
import PropTypes from 'prop-types';
import { Controller } from "react-hook-form";

/**
 * A react-hook-form controlled hidden text input
 * 
 * @param {Object} props - Component properties (see PropTypes)
 */
const Checkbox = ({
  id,
  name,
  control,
  validation,
  label,
}) => {
  return (
    <Controller
      id={id}
      name={name}
      control={control}
      rules={validation}
      render={({
        field,
        fieldState: { error }
      }) => (
        <div className="Checkbox">
          <label className="Checkbox__label">
            <input
              type='checkbox'
              className="Checkbox__input"
              {...field}
            />
            <span className="Checkbox__control" />
            <span className="Checkbox__text">{ label }</span>
          </label>
          { error && (
            <div className="Checkbox__error">{error.message}</div>
          )}
        </div>
      )}
    />
  );
}

export const CheckboxProps = {
  id: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object,
  validation: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export const CheckboxDefaults = {
  id: '',
  name: '',
  control: null,
  validation: null,
  label: '',
};

Checkbox.propTypes = CheckboxProps;
Checkbox.defaultProps = CheckboxDefaults;

export default Checkbox;

/**
 * Redux dependencies
 */
import { createSlice } from '@reduxjs/toolkit';

/**
 * Local dependencies
 */
import initialState from './initialState';
import reducers from './reducers';

const cookieSlice = createSlice({
  name: 'cookies',
  initialState,
  reducers
});

export const cookieActions = cookieSlice.actions;

export default cookieSlice;

/**
 * Redux dependencies
 */
import { createSlice } from "@reduxjs/toolkit";

/**
 * Local dependencies
 */
import initialState from './initialState';
import reducers from './reducers';

const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers,
});

export const apiActions = apiSlice.actions;

export default apiSlice;
const reducers = {
  setNotificationDisplay(state, action) {
    state.notificationDisplay = action.payload;
  },
  setEnableAnalyticsCookies(state, action) {
    state.enableAnalyticsCookies = action.payload;
  },
  setNotificationDeclined(state, action) {
    state.notificationDeclined = action.payload;
  }
}

export default reducers;
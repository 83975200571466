const reducers = {
  setVenue(state, action) {
    const { venue } = action.payload;
    state.venue = venue;
  },
  setSubmission(state, action) {
    const { userWon, prize } = action.payload;
    state.userWon = userWon;
    state.prize = prize;
  },
  setJoke(state, action) {
    const { joke } = action.payload;
    state.joke = {...joke};
  }
};

export default reducers;
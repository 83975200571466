/**
 * React dependencies
 */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

/**
 * External dependencies
 */
import _get from 'lodash.get';

/**
 * Local dependencies
 */
import validation from 'utils/validation';
import Textfield from 'components/Textfield';
import { submitFormData } from 'utils/axios';
import HiddenTextfield from 'components/HiddenTextfield';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import { ROUTE_RESULT } from 'constants/routes';
import { SEVERITY_ERROR } from 'constants/snackbar';
import { apiActions } from 'store/slices/api';
import { snackbarActions } from 'store/slices/snackbar';

/**
 * Data capture form page
 * Shows a form that user has to fill in to enter the prize draw.
 */
const DataCapture = () => {
  const { venue } = useSelector(state => state.api);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useLocation().search;
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control } = useForm();

  const compassPrivacy = "https://www.compass-group.co.uk/privacy-notice/";

  /**
   * Data capture form submit handler.
   * Sends the form data to the api to submit
   * the entry for the prize draw.
   * 
   * @param {Object} data - the submitted form data
   */
  const onSubmit = data => {
    setLoading(true);
    submitFormData( data )
      .then( response => {
        const entryResult = _get( response, 'data.data.entry_result', 0 );
        const prize       = _get( response, 'data.data.prize.description', null );

        dispatch(apiActions.setSubmission({
          userWon: entryResult === 1 ? true : false,
          prize: !!prize ? prize : null
        }));
        navigate(`${ROUTE_RESULT}${search}`);
      })
      .catch( e => {
        setLoading(false);
        dispatch(snackbarActions.setSeverity({
          severity: SEVERITY_ERROR
        }));
        dispatch(snackbarActions.setMessage({
          message: 'Server connection error.'
        }));
        dispatch(snackbarActions.showSnackBar(true));
      });
  }

  return (
    <section className="DataCapture">
      <h3 className="h3">Fill in your details</h3>
      <p>If you're a lucky winner we'll email your voucher to you.</p>

      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)} className="DataCapture__form">
        <Textfield
          id='given-name'
          label='First name'
          type='text'
          name='first-name'
          control={control}
          validation={validation.firstName}
        />
        <Textfield
          id='family-name'
          label='Last name'
          type='text'
          name='last-name'
          control={control}
          validation={validation.lastName}
        />
        <Textfield
          id='email'
          label='Email address'
          type='email'
          name='email'
          control={control}
          validation={validation.email}
        />

        <div className="DataCapture__privacy">
          {venue.venue_type === '1' ? (
            <p>By entering the competition you agree to receive the latest news, offers and announcements from {venue.venue_name} and the Levy Conference & Events team, part of Compass Group UK & Ireland. <br/><br/>We will manage your data in accordance with the <a target="_blank" rel="noreferrer" href={venue.privacy_policy}>{venue.venue_name} privacy policy</a> and <a target="_blank" rel="noreferrer" href={compassPrivacy}>Compass UK&I privacy policy</a>. You can unsubscribe at any time by emailing <a href="mailto:marketing@limevenueportfolio.com">marketing@limevenueportfolio.com</a>.</p>
          ) : (
            <p>By entering the competition you agree to receive the latest news, offers and announcements from {venue.venue_name}, part of The Venues Collection.<br/><br/>We will manage your data in accordance with the <a target="_blank" rel="noreferrer" href={compassPrivacy}>Compass UK&I privacy policy</a>. You can unsubscribe at any time by emailing <a href="mailto:marketing@limevenueportfolio.com">marketing@limevenueportfolio.com</a>.</p>
          )}
        </div>
  
        <HiddenTextfield
          control={control}
          name='marketing'
          id='marketing'
          validation={validation.marketing}
          defaultValue='true'
        />

        {venue.venue_type === '1' && (
          <HiddenTextfield
            control={control}
            name='club_specific_marketing'
            id='club_specific_marketing'
            defaultValue='true'
          />
        )}


        <HiddenTextfield
          control={control}
          name='venue'
          id='venue'
          defaultValue={venue.id}
        />
        <HiddenTextfield
          control={control}
          name='venue-name'
          id='venue-name'
          defaultValue={venue.venue_name}
        />
        <Textfield
          control={control}
          name='website'
          id='website'
          className="butwhy"
        />
        <Button
          loading={loading ? loading : undefined}
          variant='contained'
          type='submit'
          className="mx-auto"
        >
          Submit
        </Button>
      </form>
    </section>
  );
};

export default DataCapture;
/**
 * Redux dependencies
 */
 import { createSlice } from '@reduxjs/toolkit';

 /**
  * Local dependencies
  */
 import initialState from './initialState';
 import reducers from './reducers';
 
 // A notification snackbar state to use with MUI snackbar component
 // More about the component: https://mui.com/components/snackbars
 const snackbarSlice = createSlice({
   name: 'snackbar',
   initialState,
   reducers
 });
 
 export const snackbarActions = snackbarSlice.actions;
 
 export default snackbarSlice;
 
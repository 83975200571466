/**
 * React dependencies
 */
import PropTypes from 'prop-types';

/**
 * External dependencies
 */
import MUIButton from '@mui/material/Button';
import MUILoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

/**
 * Local dependencies
 */
import { COLOR_GOLD_DARK, COLOR_BLACK } from 'constants/colors';

// Custom styling to match the design requirements
const customStyles = {
  boxShadow: 'none',
  fontSize: 16,
  border: '0.2rem solid' + COLOR_BLACK,
  lineHeight: 1.6,
  padding: '0.6rem 1.2rem',
  color: COLOR_BLACK,
  backgroundColor: COLOR_GOLD_DARK,
  borderRadius: 0,
  fontFamily: "'Montserrat', Helvetica, Arial, sans-serif",
  fontWeight: '800',
  letterSpacing: '3px',
  '&:hover': {
    backgroundColor: COLOR_GOLD_DARK,
  },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  }
};

const CustomMUIButton = styled(MUIButton)(customStyles);
const CustomMUILoadingButton = styled(MUILoadingButton)(customStyles);

/**
 * An extension of the Material UI Button / LoadingButton components
 * with custom styling to match the design requirements.
 * 
 * @param {Object} props - React element properties 
 * See https://mui.com/components/buttons/ for all available props
 */
const Button = ({ loading, children, ...props }) => {
  return (
    loading ? (
      <CustomMUILoadingButton loading {...props}>
        {children}
      </CustomMUILoadingButton>
    ) : (
      <CustomMUIButton {...props}>
        {children}
      </CustomMUIButton>
    )
  );
};

export const ButtonProps = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  loading: PropTypes.bool,
};

export const ButtonDefaults = {
  children: null,
  loading: false,
};

Button.propTypes = ButtonProps;
Button.defaultProps = ButtonDefaults;

export default Button;
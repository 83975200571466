/**
 * External dependencies
 */
import anime from 'animejs/lib/anime.es.js';

/**
 * Animates the stretch of the cracker using animejs
 *  
 * @param {Object} elem - DOM element
 */
export const stretchCracker = (elem) => {
  const animationDuration = 500;
  const timeline = anime.timeline({
    targets: elem,
  });

  timeline
    .add({
      scaleX: .8,
      scaleY: 1.3,
      duration: animationDuration * 0.4,
      easing: 'easeOutBack'
    })
    .add({
      scaleX: 1,
      scaleY: 1,
      easing: 'easeOutBack'
    });
}

/**
 * Animates the pulling apart of the cracker using animejs
 *  
 * @param {Object} elem - DOM element
 * @param {String} direction - the direction to pull the cracker towards (e.g. 'top')
 * @param {Function} onComplete - optional onComplete function to fire on animation complete
 */
export const pullCrackerApart = (elem, direction, onComplete) => {
  const animationDuration = 1000;

  switch (direction) {
    case 'top':
      anime({
        targets: elem,
        duration: animationDuration,
        translateY: '-235px',
        complete: onComplete,
      });
      break;
    case 'bottom':
      anime({
        targets: elem,
        duration: animationDuration,
        translateY: '235px',
        complete: onComplete,
      });
      break;
    default:
      break;
  }
}

/**
 * Animates the fade out of an element using animejs
 * 
 * @param {Object} elem - DOM element
 * @param {Function} setRenderElement - function that sets whether to render 
 *                                      the element or not in the React component
 */
export const fadeOut = (elem, setRenderElement) => {
  anime({
    targets: elem,
    duration: 400,
    opacity: 0,
    complete: () => {
      setRenderElement(false);
    }
  });
}

/**
 * Animates the fade in of an element using animejs
 * 
 * @param {Object} elem - DOM element
 */
export const fadeIn = (elem) => {
  anime({
    targets: elem,
    duration: 400,
    opacity: 1,
  });
}
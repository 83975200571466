// Validation rules to use with forms created with react-hook-form
const validationRules = {
  firstName: {
    required: 'Please enter your first name',
  },
  lastName: {
    required: 'Please enter your last name',
  },
  email: {
    required: 'Please enter an email address',
    pattern: {
      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      message: 'Please enter a valid email address.',
    },
  },
  marketing: {
    required: 'Please check the checkbox above to continue.'
  }
};

export default validationRules;

/**
 * React dependencies
 */
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

/**
 * External dependencies
 */
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

/**
 * Local dependencies
 */
import { COLOR_BLACK, COLOR_RED, COLOR_WHITE } from 'constants/colors';

const CssTextField = styled(TextField)({
  marginBottom: '1rem',
  color: COLOR_BLACK,
  fontWeight: 800,
  '& .MuiInputLabel-root': {
    color: COLOR_BLACK,

    fontFamily: "'Montserrat', sans-serif",
    '&.Mui-error': {
      color: COLOR_BLACK,
    }
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(1rem, 1rem) scale(1)',
    '&.MuiFormLabel-filled, &.Mui-focused': {
      transform: 'translate(14px, -6px) scale(0.75)',
      color: COLOR_BLACK,
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '0.9rem 1rem',
    color: COLOR_BLACK
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '0',
    fontFamily: "'Montserrat', sans-serif",
    backgroundColor: 'rgba(255,255,255,0)',
    '& .MuiOutlinedInput-notchedOutline': {
      transition: 'background-color 0.2s ease',
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        backgroundColor: 'rgba(255,255,255,0.1)'
      }
    },
    '& fieldset': {
      borderColor: COLOR_BLACK
    },
    '&.Mui-focused fieldset': {
      borderColor: COLOR_BLACK,
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
    '&.Mui-error': {
      color: COLOR_RED,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: COLOR_RED,
      },
    },
  },
  '& .Mui-error': {
    color: COLOR_RED,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLOR_RED,
    },
  },
  '& .MuiFormHelperText-root.Mui-error': {
    margin: '0 0 0.5rem',
    backgroundColor: COLOR_RED,
    color: COLOR_WHITE,
    padding: '0.25rem',
    fontFamily: "'Montserrat', sans-serif",
  },
});

/**
 * A stylised Material text field.
 * @param {Object} props - Component properties (id, label, type)
 */
const Textfield = ({
  id,
  label,
  type,
  name,
  control,
  defaultValue,
  validation,
  helperText,
  ...restProps
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={validation}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <CssTextField
          id={id}
          label={label}
          variant='outlined'
          type={type}
          value={value || defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          error={!!error}
          fullWidth
          helperText={error ? error.message : helperText}
          {...restProps}
        />
      )}
    />
  );
};

export const TextfieldProps = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object,
  defaultValue: PropTypes.string,
  validation: PropTypes.object,
  helperText: PropTypes.string,
};

export const TextfieldDefaults = {
  id: '',
  label: '',
  type: 'text',
  name: '',
  control: null,
  defaultValue: '',
  validation: null,
  helperText: '',
};

Textfield.propTypes = TextfieldProps;
Textfield.defaultProps = TextfieldDefaults;

export default Textfield;

/**
 * React dependencies
 */
import PropTypes from 'prop-types';
import { Controller } from "react-hook-form";

/**
 * A react-hook-form controlled hidden text input
 * 
 * @param {Object} props - Component properties (see PropTypes)
 */
 const HiddenTextfield = ({
  id,
  name,
  control,
  defaultValue,
}) => {
  return (
    <Controller
      id={id}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { value },
      }) => (
        <input
          type='hidden'
          value={value || defaultValue}
          name={name}
          id={id}
          />
      )}
    />
  );
}

export const HiddenTextfieldProps = {
  id: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object,
  defaultValue: PropTypes.string,
};

export const HiddenTextfieldDefaults = {
  id: '',
  name: '',
  control: null,
  defaultValue: '',
};

HiddenTextfield.propTypes = HiddenTextfieldProps;
HiddenTextfield.defaultProps = HiddenTextfieldDefaults;

export default HiddenTextfield;
